import { Tabs } from "antd";
import VesselAgreementActiveApproval from "./vessel-agreements/vessel-agreement-active";
import VesselAgreementPendingApproval from "./vessel-agreements/vessel-agreement-pending";


const { TabPane } = Tabs;
const VesselAgreementApproval = () => {
  return (

    <Tabs destroyInactiveTabPane={true}>
      <TabPane tab="Pending" key="1">
        <VesselAgreementPendingApproval  />
      </TabPane>
      <TabPane tab="Approved" key="2">
        <VesselAgreementActiveApproval  />
      </TabPane>


    </Tabs>


  )
}

export default VesselAgreementApproval;