import { BargeCostingsService, BPFCostingRequestIdDto, ContractStatusEnum, GetDataDeadFreightDto, GetExpApprovalDataDto } from "@exportx/shared-models-and-services"
import { getLocalFormat } from "@exportx/ui-utils"
import { Input, Card, Table, Form, Row, Col, List, Button, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { AlertMessages, useAuthState } from "../../../common"
import { SequenceUtils } from "@exportx/ui-utils"
import { PaymentNoticePageEnum } from '../payment-notice'
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { DeadFreightApprovalDetails } from "../costing-detail-view/dead-freight-details";
import { InvoiceDetails } from "../costing-detail-view";

interface IProps {
  type?: ContractStatusEnum;
  paymentType?: PaymentNoticePageEnum;
}


export const DeadFreightApproval = (props: IProps) => {

  const { type, paymentType } = props
  const [formRef] = Form.useForm();

  const [gridData, setGridData] = useState<GetDataDeadFreightDto[]>();
  const bargeCostingService = new BargeCostingsService();
  const { authContext } = useAuthState();
  const [deadFreightForm, setDeadFreightForm] = useState<boolean>(false);
  const [searchedText, setSearchedText] = useState("");
  const [expandedIndex, setExpandedIndex] = useState([]);

  useEffect(() => {
    getDeadFreightData()
  }, [])

  const tableColumns: ColumnsType<any> = [
    {
      title: 'Dead Freight Ref #',
      dataIndex: 'costingNo',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record)
      },
    },
    {
      title: 'Date',
      dataIndex: 'deadFreightDate',
      render: (value, record) => { return <>{moment(value)?.format('DD-MM-YYYY')}</> }
    },

    {
      title: 'MV Name',
      dataIndex: 'businessNo',
      render: (value, record) => {
        const href = `/#/bn-detail-view?bn_Id=${record.bnId}`
        return <>
          {<a href={href} style={{ fontSize: 12 }}>{value}</a>}
        </>
      }

    },

    {
      title: 'Account Type',
      dataIndex: 'account',
    },
    {
      title: 'BusinessPartner',
      dataIndex: 'supplierName',
    },

    {
      title: 'DeadFreight QTY',
      dataIndex: 'deadFreight',
      render: (value, record) => {
        return <>
          {value + " MT"}
        </>
      }
    },
    {
      title: 'PricePerMT',
      dataIndex: 'pricePerMT',
      // width: 40,
      render: (value, record) => {
        return <>
          {getLocalFormat(Number(value), record.baseCurrency)}
        </>
      }
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      render: (value, record) => {
        return <>
          {getLocalFormat(Number(value), record.baseCurrency)}
        </>
      }
    }

  ]

  const getDeadFreightData = () => {
    const status = type === ContractStatusEnum.BUSINESS_APPROVAL
      ? ContractStatusEnum.PENDING_APPROVAL
      : ContractStatusEnum.PENDING_AUDIT_APPROVAL
    const req = new GetExpApprovalDataDto(paymentType, authContext.defaultPlant, status)
    bargeCostingService.getDeadFreightDataForApproval(req).then(res => {
      if (res.status) {
        setGridData(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.demurrageAccountId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }
  const approveStatus = type === ContractStatusEnum.BUSINESS_APPROVAL
    ? ContractStatusEnum.ACTIVE
    : ContractStatusEnum.ACTIVE

  const approveDeadFreight = (costingId: string, status: ContractStatusEnum) => {
    formRef.validateFields().then(values => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(costingId, values['remarks'], status, authContext.user.userName, authContext.defaultPlant, type);

      console.log('req =>', req)
      bargeCostingService.deadFreightApproval2(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getDeadFreightData();
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);

        }
      }).catch(err => {
        console.log(err.message)
      })
    }).catch(err => {
      console.log(err)
    })

  }
  const renderItems = (record: any, index, indent, expanded) => {
    console.log(record)
    return <>

      <DeadFreightApprovalDetails costingId={record.bgDeadFreightId} remainingAmount={0} />

      <Form
        form={formRef}
        // initialValues={initialValues}
        layout='horizontal'
        autoComplete='off'
      >
        <Row className='pt-2'>
          <Col span={16}>
            <Form.Item style={{ width: '100%' }} name='remarks' label="Work Log"
              rules={[
                {
                  required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                }
              ]}>
              <Input style={{ width: '95%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Row>
              <Col span={3}>Worklog :</Col>
              <Col span={20}>
                {record?.workLog?.length > 0 && <List
                  bordered
                  dataSource={record?.workLog ? record?.workLog : []}
                  renderItem={(item, index) => (
                    <List.Item key={index} style={{ width: "100%" }}>
                      <div><span>{index + 1}</span><span>.</span>&nbsp;{item.workLog}</div>
                    </List.Item>
                  )}
                />}

              </Col>
            </Row>

          </Col>
        </Row>
        <Row>
          <Col span={16}>
          </Col>
        </Row>
        <br />
        {record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL &&
          <Row justify='end'>
            <Col span={2} > <Button type='primary' onClick={() => approveDeadFreight(record?.bgDeadFreightId, approveStatus)} >Approve</Button></Col>
            <Col span={2} >
              <Popconfirm
                onConfirm={e => {
                  approveDeadFreight(record?.bgDeadFreightId, ContractStatusEnum.CANCELLED);
                }}
                title={'Are you sure to reject'}>
                <Tooltip placement="top" title={'Reject'}>
                  <Button style={{ color: 'red' }}>Reject</Button>
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>

        }
      </Form>

      <br />
      <InvoiceDetails
        costingId={record.bgDeadFreightId}
        status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
        level={ContractStatusEnum.BUSINESS_APPROVAL}
        invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
        invApproveDisabled={record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL}
      />
    </>
  };
  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Dead Freight Approval for MV</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}>
        <Input.Search
          placeholder="Search"
          allowClear
          onChange={(e) => { setSearchedText(e.target.value) }}
          onSearch={(value) => { setSearchedText(value) }}
          style={{ width: 200, float: "right" }}
        />
        <Table
          size='small'
          expandIconColumnIndex={0}
          // className='contracts'
          rowKey={record => record.demurrageAccountId}
          columns={tableColumns}
          dataSource={gridData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right'
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleFilled
                onClick={(e) => onExpand(record, e)}
              >
                Collapse
              </UpCircleFilled>
            ) : (
              <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
            )
          }
          pagination={false}
          scroll={{ x: 500 }}
          bordered
        />

      </Card>
    </>
  )

}
export default DeadFreightApproval