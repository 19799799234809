import Icon, * as antdIcons from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Link,
  Outlet
} from "react-router-dom";
import { CommonHeader, isPermissionExist } from '../common';
import { ReactComponent as AccountGroups } from '../common/icons/account-groups.svg';
import { ReactComponent as Ledger } from '../common/icons/accounts.svg';
import { ReactComponent as AnchorageIcon } from '../common/icons/anchor.svg';
import { ReactComponent as ApprovalIcon } from '../common/icons/approved.svg';
import { ReactComponent as LcIcon } from '../common/icons/bank.svg';
import { ReactComponent as CostingsIcon } from '../common/icons/budget.svg';
import { ReactComponent as BudgetAndCostingIcon } from '../common/icons/budgeting.svg';
import { ReactComponent as VendorTypeIcon } from '../common/icons/business-partner.svg';
import { ReactComponent as Cargo } from '../common/icons/cargo-barge.svg';
import { ReactComponent as SamplingIcon } from '../common/icons/chemical-testing.svg';
import { ReactComponent as Coins } from '../common/icons/coins.svg';
import { ReactComponent as CommoditiesIcon } from '../common/icons/commodities.svg';
import { ReactComponent as CompanyIcon } from '../common/icons/company.svg';
import { ReactComponent as AdvanceContractIcon } from '../common/icons/contract.svg';
import { ReactComponent as CountryIcon } from '../common/icons/countries.svg';
import { ReactComponent as CurrencyExchangeIcon } from '../common/icons/currency-exchange.svg';
import { ReactComponent as CurrencyIcon } from '../common/icons/currency.svg';
import { ReactComponent as DestinationIcon } from '../common/icons/delivery-route.svg';
import { ReactComponent as ReceiptVoucher } from '../common/icons/discount-vouchers.svg';
import { ReactComponent as ContractsDoc } from '../common/icons/document.svg';
import { ReactComponent as FinanceApprove } from '../common/icons/finance-approve.svg';
import { ReactComponent as FinReports } from '../common/icons/finance-report.svg';
import { ReactComponent as PurchaseSamplingIcon } from '../common/icons/financial-experiment-p.svg';
import { ReactComponent as SalesSamplingIcon } from '../common/icons/financial-experiment-s.svg';
import { ReactComponent as BusinessNoIcon } from '../common/icons/financial-planning.svg';
import { ReactComponent as CustomerTypeIcon } from '../common/icons/global-business-partner.svg';
import { ReactComponent as BasisIcon } from '../common/icons/humidity-mobile-application.svg';
import { ReactComponent as JVoucher } from '../common/icons/journal.svg';
import { ReactComponent as LedgerBook } from '../common/icons/ledger-book.svg';
import { ReactComponent as BusinessPartnerIcon } from '../common/icons/management.svg';
import { ReactComponent as UOMIcon } from '../common/icons/measuring-tape.svg';
import { ReactComponent as BargeIcon } from '../common/icons/noun-barge-3447518.svg';
import { ReactComponent as FacilitatorTypeIcon } from '../common/icons/partnership.svg';
import { ReactComponent as PayableIcon } from '../common/icons/payable.svg';
import { ReactComponent as PaymentModesIcon } from '../common/icons/payment-modes.svg';
import { ReactComponent as FacilitatorIcon } from '../common/icons/people-conversation.svg';
import { ReactComponent as CustomerIcon } from '../common/icons/people.svg';
import { ReactComponent as PersonIcon } from '../common/icons/person.svg';
import { ReactComponent as ColaPriceIndexIcon } from '../common/icons/pricing.svg';
import { ReactComponent as PCIcon } from '../common/icons/purchase-contracts.svg';
import { ReactComponent as ReceivableIcon } from '../common/icons/receivable.svg';
import { ReactComponent as SCIcon } from '../common/icons/sales-contract.svg';
import { ReactComponent as ShippingTermsIcon } from '../common/icons/shipping-contract.svg';
import { ReactComponent as ApproveReject } from '../common/icons/take-decision.svg';
import { ReactComponent as ItemsAndTaxesIcon } from '../common/icons/tax.svg';
import { ReactComponent as TDSIcon } from '../common/icons/tds.svg';
import { ReactComponent as UserManagementIcon } from '../common/icons/user-management.svg';
import { ReactComponent as TaxIcon } from '../common/icons/vat-tax.svg';
import { ReactComponent as Voucher } from '../common/icons/voucher.svg';
import './BasicLayout.css';
import { AttributePage, ApplicationPage, ModulePage, MenuPage, SubMenuPage, ScopePage, PermissionsPage, OrganizationPage, ClientToApplicationsMapping, UnitPage, RolesPage, UserPage, UserRoleMappings, RolePermissionsMapping } from '../pages';
import CostingReports from '../pages/costings/reports/costing-reports';
import { PieChartTwoTone } from '@ant-design/icons';

const { Sider, Content } = Layout;

export const menus = [
  {
    path: '/masters',
    title: 'Masters',
    icon: <antdIcons.HomeOutlined />,
    component: null,
    show: isPermissionExist([3,
      7,
      11,
      15,
      19,
      23,
      27,
      31,
      35,
      39,
      43,
      47,
      51,
      55,
      59,
      63,
      67,
      71]),
    children: [

      {
        path: '/countries',
        title: 'Countries',
        icon: <Icon component={CountryIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([3]),
        component: <></>
      },
      {
        path: '/currency',
        title: 'Currencies',
        icon: <Icon component={CurrencyIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([7]),
        component: <></>
      },
      {
        path: '/company',
        title: 'Company',
        icon: <Icon component={CompanyIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([11]),
        component: <></>
      },
      {
        path: '/commodity',
        title: 'Commodity',
        icon: <Icon component={CommoditiesIcon} style={{ fontSize: '20px', color: 'blue' }} />,
        show: isPermissionExist([15]),
        component: <></>
      },
      {
        path: '/basis',
        title: 'Basis',
        icon: <Icon component={BasisIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([19]),
        component: <></>
      },
      {
        path: '/taxes-view',
        title: 'Taxes',
        icon: <Icon component={TaxIcon} style={{ fontSize: '20px', color: 'blue' }} />,
        show: isPermissionExist([23]),
        component: null,
        children: []
      },
      {
        path: "/items-and-taxes",
        title: "Items And Taxes",
        icon: <Icon component={ItemsAndTaxesIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([27]),
        component: null,
        children: []
      },
      {
        path: '/company-vat',
        title: 'Company TDS',
        icon: <Icon component={TDSIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([31]),
        component: null,
        children: []
      },
      {
        path: '/currency-conversion',
        title: 'Exchange Rates',
        icon: <Icon component={CurrencyExchangeIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([35]),
        component: <></>
      },
      {
        path: '/vendorType',
        title: 'VendorType',
        icon: <Icon component={VendorTypeIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([39]),
        component: <></>
      },
      {
        path: '/customerType',
        title: 'CustomerType',
        icon: <Icon component={CustomerTypeIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([43]),
        component: <></>
      },
      {
        path: '/facilitatorType',
        title: 'facilitatorType',
        icon: <Icon component={FacilitatorTypeIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([47]),
        component: <></>
      },
      {
        path: '/shippingTerms',
        title: 'ShippingTerms',
        icon: <Icon component={ShippingTermsIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([51]),
        component: <></>
      },
      {
        path: '/paymentModes',
        title: 'PaymentModes',
        icon: <Icon component={PaymentModesIcon} style={{ fontSize: '20px', color: 'blue' }} />,
        show: isPermissionExist([55]),
        component: <></>
      },
      {
        path: '/UOMs-view',
        title: `UOM's`,
        icon: <Icon component={UOMIcon} style={{ fontSize: '20px', color: 'blue' }} />,
        show: isPermissionExist([59]),
        component: null,
      },
      {
        path: '/destination-view',
        title: `Province`,
        icon: <Icon component={DestinationIcon} style={{ fontSize: '20px', color: 'blue' }} />,
        show: isPermissionExist([63]),
        component: null,
        children: []
      },
      {
        path: '/anchoragegrid',
        title: 'Anchorage',
        icon: <Icon component={AnchorageIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([67]),
        component: null,
        children: []
      },
      {
        path: '/jettyGrid',
        title: 'Jetty',
        icon: <Icon component={AnchorageIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([]),
        component: null,
        children: [71]
      }
    ],
  },
  {
    path: '/business-partner',
    title: 'Business Partner',
    icon: <Icon component={BusinessPartnerIcon} style={{ fontSize: '20px' }} />,
    show: isPermissionExist([75, 82, 89]),
    component: <></>,
    children: [
      {
        path: '/customer',
        title: 'Customers',
        icon: <Icon component={CustomerIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([75]),
        component: <></>
      },
      {
        path: '/vendor',
        title: 'Vendors',
        icon: <Icon component={PersonIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([82]),
        component: <></>
      },
      {
        path: '/facilitator',
        title: 'Facilitator',
        icon: <Icon component={FacilitatorIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([89]),
        component: <></>
      }
    ]
  },
  {
    path: '/contracts',
    title: 'Contracts',
    icon: <Icon component={ContractsDoc} style={{ fontSize: '20px' }} />,
    show: isPermissionExist([96, 111, 123, 126, 126, 134, 138, 147, 156, 165, 174, 182, 189, 194, 202, 209, 214, 222, 229, 234, 242, 249]),
    component: null,
    children: [
      {
        path: '/sales',
        title: `Sales`,
        icon: <Icon component={SCIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([95, 96, 108]),
        component: null,
        children: [
          {
            path: '/so-creation',
            title: 'SO Creation',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([95]),
            children: []
          },
          {
            path: '/so-tracker',
            title: 'SO Tracker',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([96]),
            children: []
          },
          {
            path: '/sales-transfer-log',
            title: 'Sales Transfer',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([108]),
            children: []

          }
        ]
      },
      {
        path: '/purchase',
        title: 'Purchase',
        icon: <Icon component={PCIcon} style={{ fontSize: '20px' }} />,
        show: isPermissionExist([110, 111, 123]),
        component: null,
        children: [
          {
            path: '/po-creation',
            title: 'PO Creation',
            icon: <antdIcons.PayCircleOutlined />,
            show: isPermissionExist([110]),
            component: null,
            children: []
          },
          {
            path: '/po-tracker',
            title: 'PO Tracker',
            icon: <antdIcons.PayCircleOutlined />,
            show: isPermissionExist([111]),
            component: null,
            children: []
          },
          {
            path: '/purchase-transfer-log',
            title: 'Purchase Transfer',
            icon: <antdIcons.PayCircleOutlined />,
            show: isPermissionExist([123]),
            component: null,
            children: []

          }
        ]
      },
      {
        path: '/coal-price-indexes',
        title: 'Coal Price Indexes',
        icon: <Icon component={ColaPriceIndexIcon} style={{ fontSize: '20px' }} />,
        component: null,
        show: isPermissionExist([126, 130, 134]),
        children: [
          {
            path: '/weekly-coal-price-index',
            title: 'Weekly',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([126]),
            children: []
          },
          {
            path: '/coal-price-calculation',
            title: 'Price Calculation',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([130]),
            children: []
          },
          {
            path: '/monthly-coal-price-index',
            title: 'Monthly',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([134]),
            children: []
          },
        ]
      },
      {
        path: 'fixtureNoteDetails',
        title: 'FixtureNote',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([138, 137]),
        children: [

          // {
          //   path: '/bargeAgreement-creation',
          //   title: 'Barging Agreement Creation ',
          //   icon: <antdIcons.PayCircleOutlined />,
          //   component: null,
          //   children: [],
          //   show: isPermissionExist([137]),
          // },
          {
            path: '/bargeAgreement-grid',
            title: 'Barging Agreement ',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            children: [],
            show: isPermissionExist([138]),
          },
          {
            path: '/vesselAgreement-grid',
            title: 'Vessel Agreement',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            children: [],
            show: isPermissionExist([138]),
          },
        ]
      },
      {
        path: 'procurement-service',
        title: 'Service Contracts',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([147,
          156,
          165]),
        children: [
          {
            path: '/procurementServiceFee',
            title: 'Procurement Service',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            children: [],
            show: isPermissionExist([147]),
          },
          {
            path: '/coalServiceFee',
            title: 'Coal Service Fees',
            icon: <antdIcons.TeamOutlined />,
            show: isPermissionExist([156]),
            component: <></>
          },
          {
            path: '/marketing',
            title: 'Marketing Fees',
            icon: <antdIcons.TeamOutlined />,
            show: isPermissionExist([165]),
            component: <></>
          },
        ]
      },
      {
        path: '/Advance',
        title: `Advance`,
        icon: <Icon component={AdvanceContractIcon} style={{ fontSize: '20px' }} />,
        component: null,
        show: isPermissionExist([174, 194, 214, 234]),
        children: [
          {
            path: '/vendor-advance',
            title: 'Vendor Advance',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([174]),
            children: []
          },

          {
            path: '/down-payment',
            title: 'Down Payment',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([194]),
            children: []
          },
          {
            path: '/vendor-advance-against-contract',
            title: 'Advance Against Contract',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([214]),
            children: []
          },
          {
            path: '/advance-receivables',
            title: 'Advance Receivables',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([234]),
            children: []
          }
        ]
      },
      {
        path: '/contract-approval',
        title: `Approvals`,
        icon: <Icon component={ApprovalIcon} style={{ fontSize: '20px' }} />,
        component: null,
        show: isPermissionExist([104, 119, 151, 177, 197]),
      },
    ]
  },
  {
    path: 'businessnumberDetails',
    title: 'Business Number',
    icon: <Icon component={BusinessNoIcon} style={{ fontSize: '20px' }} />,
    component: null,
    show: isPermissionExist([254]),
    children: [
      {
        path: '/businessnumberDetails',
        title: 'Business Number Creation',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([253]),
        children: []
      },
      {
        path: '/businessnumber',
        title: 'Business Number Grid',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([254]),
        children: []
      }
    ]
  },
  {
    path: 'BusinessReports',
    title: `Business Report`,
    icon: <Icon />,
    component: null,
    show: isPermissionExist([261]),
    children: [
      {
        path: '/PLReports',
        title: 'PL Reports',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([261]),
        children: []
      }
      // {
      //     path :'/PLChildReports',
      //     title:'PL Child Reports',
      //     icon: <antdIcons.PayCircleOutlined />,
      //     component: null,
      //     children: []
      // }
    ]
  },
  {
    path: 'Barge',
    title: `Barge`,
    icon: <Icon component={Cargo} style={{ fontSize: '20px' }} />,
    component: null,
    show: isPermissionExist([263, 262]),
    children: [
      {
        path: '/createBarge',
        title: `Barge Creation`,
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([262]),
        children: []
      },
      {
        path: '/barge-data',
        title: `Barges`,
        icon: <Icon component={BargeIcon} style={{ fontSize: '20px' }} />,
        component: null,
        show: isPermissionExist([263]),
        children: []
      }
    ]
  },
  {
    path: '/costings',
    title: 'Costings Creation',
    icon: <Icon component={CostingsIcon} style={{ fontSize: '20px' }} />,
    component: null,
    show: isPermissionExist([271, 292, 313, 334, 355, 376, 396, 417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667, 687, 707, 727, 747]),
    children: [
      {
        path: 'performaCoalCosting',
        title: `Account Payables`,
        icon: <antdIcons.RotateRightOutlined />,
        component: null,
        show: isPermissionExist([271]),
        children: [
          {
            path: 'performaCoalCosting',
            title: `Performa Purchase`,
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([271]),
          },

          {
            path: 'commercial-coal-costing',
            title: `Commercial Purchase`,
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([292]),
            children: []
          },

          {
            path: 'procurement-coal-costing',
            title: `Procurement Fees`,
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([355]),
            children: []
          },
          {
            path: 'marketing-coal-costing',
            title: `Marketing Fees`,
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([376]),
            children: []
          },
          {
            path: '/barge-costings/barge',
            title: 'Barge Costing',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([396]),
            children: []
          },
          {
            path: '/expense-entry',
            title: 'Expense Entry',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667, 687,]),
            children: []
          },
          {
            path: '/dead-freight',
            title: 'Dead Freight',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([707]),
            children: []
          },
          {
            path: '/demurrage',
            title: 'Demurrage',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([727]),
            children: []
          },
          {
            path: '/credit-note-entry',
            title: 'Credit note',
            icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
            show: isPermissionExist([747]),
            component: <></>
          },
        ]
      },
      {
        path: 'performaCostingSale',
        title: `Account Receivables`,
        icon: <antdIcons.RotateLeftOutlined />,
        component: null,
        show: isPermissionExist([313]),
        children: [
          {
            path: 'performaCostingSale',
            title: `Performa Sale`,
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([313])
          },

          {
            path: 'commercial-costing-sales',
            title: `Commercial Sales`,
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([334]),
            children: []
          },
          {
            path: '/other-income-expense-entry',
            title: 'Expense Entry',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667, 687,]),
            children: []
          },
          {
            path: '/debit-note-entry',
            title: 'Debit Note',
            icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
            show: isPermissionExist([747]),
            component: <></>
          },
        ]
      },
    ]
  },
  {
    path: '/costingApprovals',
    title: 'Business Approvals',
    icon: <Icon component={ApproveReject} style={{ fontSize: '20px' }} />,
    component: null,
    show: true,
    children: [
      {
        path: 'Account_payables',
        title: `Account Payables`,
        icon: <antdIcons.RotateRightOutlined />,
        component: null,
        show: isPermissionExist([275, 296, 317, 338, 359, 380, 400, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690, 710, 730, 750]),
        children: [
          {
            path: '/performa-pc-approval',
            title: 'Performa Purchase',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([275]),
            children: []
          },
          {
            path: '/commercial-pc-approval',
            title: 'Commercial Purchase',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([317]),
            children: []
          },
          {
            path: '/procurement-approval',
            title: 'Procurement Fees',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([359]),
            children: []
          },
          {
            path: '/marketing-approval',
            title: 'Marketing Fees',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([380]),
            children: []
          },

          {
            path: '/barge-costings/barge-approval',
            title: 'Barge Costing',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([400]),
            children: []
          },
          {
            path: '/expense-entry-approval',
            title: 'Expense Entry',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690,]),
            children: []
          },
          {
            path: '/dead-freight-approval',
            title: 'Dead Freight',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([710]),
            children: []
          },
          {
            path: '/demurrage-approval',
            title: 'Demurrage ',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([730]),
            children: []
          },
          {
            path: '/credit-note-approval',
            title: 'Credit Approval',
            icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
            show: isPermissionExist([750]),
            component: <></>
          },
          {
            path: '/advance-business-approval',
            title: 'Advances',
            icon: <antdIcons.PayCircleOutlined />,
            // show: isPermissionExist([750]),
            show: true,
            component: <></>
          },
        ]
      },
      {
        path: 'Account_receivables',
        title: `Account Receivables`,
        icon: <antdIcons.RotateRightOutlined />,
        component: null,
        show: isPermissionExist([298, 712, 732]),
        children: [
          {
            path: '/performa-sc-approval',
            title: 'Performa Sales',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([296]),
            children: []
          },
          {
            path: '/commercial-sc-approval',
            title: 'Commercial Sales',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([334, 338]),
            children: []
          },
          {
            path: '/rec-expense-entry-approval',
            title: 'Expense Entry',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690]),
            children: []
          },
          {
            path: '/dead-freight-receivable-approval',
            title: 'Dead Freight',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([710]),
            children: []
          },
          {
            path: '/demurrage-receivable-approval',
            title: 'Demurrage',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([730]),
            children: []
          },
          {
            path: '/debit-note-approval',
            title: 'Debit Approval',
            show: isPermissionExist([750]),
            icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
            component: <></>
          },
          {
            path: '/advance-receivable-business-approval',
            title: 'Advance Receivables',
            show: isPermissionExist([750]),
            icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
            component: <></>
          },
        ]
      },




      // {
      //     path: '/commercial-sc-approval',
      //     title: 'Commercial Sales',
      //     icon: <antdIcons.PayCircleOutlined />,
      //     component: null,
      //      show: isPermissionExist([338])
      //     children: [],

      // },

      //TODO:have to check
      // {
      //     path: '/coalCostView',
      //     title: 'CoalCostView',
      //     icon: <antdIcons.PayCircleOutlined />,
      //     component: null,
      //     children: []
      // },

    ]
  },
  {
    path: '/auditApprovals',
    title: 'Audit Approvals',
    icon: <antdIcons.AuditOutlined style={{ fontSize: 15 }} />,
    show: isPermissionExist([334, 338, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690]),

    // icon: <Icon component={ApproveReject} style={{ fontSize: '20px' }} />,
    component: null,
    children: [
      {
        path: '/account-payable-audit-approval',
        title: 'Account Payables',
        icon: <antdIcons.RotateRightOutlined />,
        component: null,
        show: isPermissionExist([276]),
        children: []
      },
      {
        path: '/account-receivable-audit-approval',
        title: 'Account Receivables',
        icon: <antdIcons.RotateLeftOutlined />,
        component: null,
        show: isPermissionExist([276]),
        children: []
      },

    ]
  },
  {
    path: '/managementApprovals',
    title: 'Management Review',
    show: isPermissionExist([298, 712, 732]),
    icon: <antdIcons.UsergroupAddOutlined style={{ fontSize: 15 }} />,

    // icon: <Icon component={ApproveReject} style={{ fontSize: '20px' }} />,
    component: null,
    children: [
      {
        path: 'Account_payables',
        title: `Account Payables`,
        icon: <antdIcons.RotateRightOutlined />,
        component: null,
        show: isPermissionExist([298, 712, 732]),
        children: [
          {
            path: '/commercial-pc-management-approval',
            title: 'Commercial Purchase',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([298]),
            children: []
          },
          {
            path: '/dead-freight-management-approval',
            title: 'Dead Freight',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([712]),
            children: []
          },
          {
            path: '/demurrage-management-approval',
            title: 'Demurrage ',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([732]),
            children: []
          },
        ]
      },

      {
        path: 'Account_Receivables',
        title: `Account Receivables`,
        icon: <antdIcons.RotateLeftOutlined />,
        component: null,
        show: true,
        children: [
          {
            path: '/commercial-sc-approval',
            title: 'Commercial Sales',
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([298]),
            children: []
          },
        ]
      }

    ]
  },
  {
    path: '/costingsFinance',
    title: 'Costing Finance',
    show: isPermissionExist([287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538, 559, 580, 601, 622, 642, 662, 682, 702, 722, 742, 762]),
    icon: <Icon component={FinanceApprove} style={{ fontSize: '20px' }} />,
    component: null,
    children: [
      // {
      //   path: '/barge-costings/barge-pmt-notice',
      //   title: 'Advance Adjustment - Barge',
      //   icon: <antdIcons.PayCircleOutlined />,
      //   component: null,
      //   show: isPermissionExist([412]),
      //   children: []
      // },
      {
        path: '/payment-notice-creation',
        title: 'Payment Notice Creation',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538, 559, 580, 601, 622, 642, 662, 682, 702, 722, 742, 762]),
        children: []
      },
      {
        path: '/payment-notice-approval',
        title: 'Payment Notice Approval',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([288, 309, 330, 351, 372, 392, 413, 434, 455, 476, 497, 518, 539, 560, 581, 602, 623, 643, 663, 683, 703, 723, 743, 763]),
        children: []
      },
      {
        path: '/payment-notice-release',
        title: 'Payment Notice Release',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([290, 311, 332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725, 745, 765]),
        children: []
      },
      {
        path: '/closed-payment-notices',
        title: 'Closed Payment Notices',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([290, 311, 332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725, 745, 765]),
        children: []
      },
      // {
      //   path: '/dead-freight-accounts-entry',
      //   title: 'Dead Freight Accounts Entry',
      //   icon: <antdIcons.PayCircleOutlined />,
      //   component: null,
      //   show: isPermissionExist([722]),
      //   children: []
      // },
      {
        path: '/demurrage-accounts-entry',
        title: 'Demurrage Accounts Entry',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([742]),
        children: []
      },
    ]
  },
  // {
  //   path: '/receivedApprovals',
  //   title: 'Received Approvals',
  //   show: isPermissionExist([334, 338, 421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690]),
  //   icon: <Icon component={ApproveReject} style={{ fontSize: '20px' }} />,
  //   component: null,
  //   children: [
  //     {
  //       path: '/commercial-sc-approval',
  //       title: 'Commercial Sales',
  //       icon: <antdIcons.PayCircleOutlined />,
  //       component: null,
  //       show: isPermissionExist([334, 338]),
  //       children: []
  //     },
  //     {
  //       path: '/rec-expense-entry-approval',
  //       title: 'Expense Entry',
  //       icon: <antdIcons.PayCircleOutlined />,
  //       component: null,
  //       show: isPermissionExist([421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690]),
  //       children: []
  //     },
  //     {
  //       path: '/dead-freight-receivable-approval',
  //       title: 'Dead Freight',
  //       icon: <antdIcons.PayCircleOutlined />,
  //       component: null,
  //       show: isPermissionExist([710]),
  //       children: []
  //     },
  //     {
  //       path: '/demurrage-receivable-approval',
  //       title: 'Demurrage',
  //       icon: <antdIcons.PayCircleOutlined />,
  //       component: null,
  //       show: isPermissionExist([730]),
  //       children: []
  //     },
  //     {
  //       path: '/debit-note-approval',
  //       title: 'Debit Approval',
  //       show: isPermissionExist([750]),
  //       icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
  //       component: <></>
  //     },

  //   ]
  // },
  {
    path: '/receivables',
    title: 'Receivables',
    show: isPermissionExist([287, 308, 329, 350, 371, 391, 412, 433, 454, 475, 496, 517, 538, 559, 580, 601, 622, 642, 662, 682, 702, 722, 742, 762]),
    icon: <Icon component={FinanceApprove} style={{ fontSize: '20px' }} />,
    component: null,
    children: [
      // {
      //     path: '/aprove-payment',
      //     title: 'Receive Payment',
      //     icon: <antdIcons.PayCircleOutlined />,
      //     component: null,
      //     children: []
      // },
      {
        path: '/approve-payment',
        title: 'Approve Payment',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([288, 309, 330, 351, 372, 392, 413, 434, 455, 476, 497, 518, 539, 560, 581, 602, 623, 643, 663, 683, 703, 723, 743, 763]),
        children: []
      },
      {
        path: '/release-payment',
        title: 'Release Payment',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([290, 311, 332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725, 745, 765]),
        children: []
      },
      {
        path: '/receivable-closed-payment',
        title: 'Closed Payment',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([290, 311, 332, 353, 374, 394, 415, 436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705, 725, 745, 765]),
        children: []
      },
      // {
      //   path: '/dead-freight-receivable-transactions',
      //   title: 'Dead Freight',
      //   icon: <antdIcons.PayCircleOutlined />,
      //   component: null,
      //   show: isPermissionExist([722]),
      //   children: []
      // },
      {
        path: '/demurrage-receivable-transactions',
        title: 'Demurrage',
        icon: <antdIcons.PayCircleOutlined />,
        component: null,
        show: isPermissionExist([742]),
        children: []
      },
    ]
  },
  {
    path: '/sampling-analysis',
    title: `Sampling Analysis`,
    show: isPermissionExist([837,
      842,
      847,
      852,
      857,
      861,
      865,
      869,
      873,
      877,
      882,
      886]),
    icon: <Icon component={SamplingIcon} style={{ fontSize: '20px' }} />,
    component: null,
    children: [
      {
        path: '/purchase-sampling',
        title: `Purchase Quality`,
        icon: <Icon component={PurchaseSamplingIcon} style={{ fontSize: '20px' }} />,
        component: null,
        show: isPermissionExist([837,
          842,
          847,
          852,
          857,
          861,
          865,
          869,
          873]),
        children: [

          {
            path: 'PurchaseS&A',
            title: `Purchase S&A`,
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([837,
              842,
              847,
              852]),
            children: [
              {
                path: '/purchase-sampling/supplier',
                title: `S&A Supplier`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([837]),
                children: []
              },

              {
                path: '/purchase-sampling/company',
                title: `S&A SRPL`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([842]),
                children: []
              },
              {
                path: '/purchase-sampling/actual',
                title: `S&A Actual`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([847]),
                children: []
              },
              {
                path: '/purchase-sampling/shipment',
                title: `S&A Pre-Shipment`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([852]),
                children: []
              },
            ]
          },
          {
            path: '',
            title: `Reports`,
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            show: isPermissionExist([857,
              861,
              865,
              869,
              873]),
            children: [
              {
                path: '/purchase-sampling-reports/supplier',
                title: `Purchase Supplier Report`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([857]),
                children: []
              },
              {
                path: '/purchase-sampling-reports/company',
                title: `SRPL Reports`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([861]),
                children: []
              },
              {
                path: '/purchase-sampling-reports/actual',
                title: `Actual Report`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([865]),
                children: []
              },
              {
                path: '/purchase-sampling-reports/aging-report',
                title: `Purchase Aging Report`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([869]),
                children: []
              },
              {
                path: '/purchase-sampling-reports/pre-shipment',
                title: `Pre-Shipment Report`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([873]),
                children: []
              },
            ]
          },


        ]
      },
      {
        path: 'salesQuality',
        title: `Sales Quality`,
        show: isPermissionExist([877,

          882,
          886]),
        icon: <Icon component={SalesSamplingIcon} style={{ fontSize: '20px' }} />,
        component: null,
        children: [
          {
            path: '/sales-sampling',
            title: `Sale S&A`,
            show: isPermissionExist([877]),
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            children: [
              {
                path: 'sales-sampling/actual',
                title: `S&A Actual`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([877]),
                children: []
              },
            ]

          },
          {
            path: '/Reports',
            title: `Report`,
            show: isPermissionExist([882, 886]),
            icon: <antdIcons.PayCircleOutlined />,
            component: null,
            children: [
              {
                path: 'sales-sampling-reports/actual',
                title: `Supplier Report `,
                icon: <antdIcons.PayCircleOutlined />,
                show: isPermissionExist([882]),
                component: null,
                children: []
              },
              {
                path: '/sales-sampling-reports/aging-report',
                title: `Aging Report`,
                icon: <antdIcons.PayCircleOutlined />,
                component: null,
                show: isPermissionExist([886]),
                children: []
              },
            ]

          },


        ]

      }
    ]
  },
  {
    path: '/budget-costing',
    title: 'CostingDetails',
    show: isPermissionExist([767]),
    icon: <Icon component={BudgetAndCostingIcon} style={{ fontSize: '20px' }} />,
    component: null,
    children: []
  },
  {
    path: '/lc-tracker',
    title: 'Lc Tracker',
    show: isPermissionExist([773, 779]),
    icon: <Icon component={LcIcon} style={{ fontSize: '20px' }} />,
    component: null,
    children: [
      {
        path: '/purchase-lc-tracker',
        title: 'Purchase LcTracker',
        show: isPermissionExist([773]),
        icon: <Icon component={AccountGroups} style={{ fontSize: '20px' }} />,
        component: <></>
      },
      {
        path: '/sales-lc-tracker',
        title: 'Sales LcTracker',
        show: isPermissionExist([779]),
        icon: <Icon component={Ledger} style={{ fontSize: '20px' }} />,
        component: <></>
      },
    ]
  },
  {
    path: '/accountsModule',
    title: 'Accounts',
    show: isPermissionExist([785,
      791,
      797,
      803,
      809,
      815,
      821,
      827]),
    icon: <Icon component={Coins} style={{ fontSize: '20px' }} />,
    component: null,
    children: [
      {
        path: '/account-groups',
        title: 'Accounts Groups',
        show: isPermissionExist([785]),
        icon: <Icon component={AccountGroups} style={{ fontSize: '20px' }} />,
        component: <></>
      },
      {
        path: '/accounts',
        title: 'Ledgers',
        show: isPermissionExist([791]),
        icon: <Icon component={Ledger} style={{ fontSize: '20px' }} />,
        component: <></>
      },
      {
        path: '/payment-voucher',
        title: 'Payment Vouchers',
        show: isPermissionExist([797]),
        icon: <Icon component={Voucher} style={{ fontSize: '20px' }} />,
        component: <></>
      },
      {
        path: '/receipt-voucher',
        title: 'Receipt Vouchers',
        show: isPermissionExist([803]),
        icon: <Icon component={ReceiptVoucher} style={{ fontSize: '20px' }} />,
        component: <></>
      },
      {
        path: '/journalVoucher',
        title: 'Journal Vouchers',
        show: isPermissionExist([809]),
        icon: <Icon component={JVoucher} style={{ fontSize: '20px' }} />,
        component: <></>
      },

      {
        path: '/reports',
        title: 'Accounting Reports',
        show: isPermissionExist([815, 821, 827]),
        icon: <Icon component={FinReports} style={{ fontSize: '20px' }} />,
        component: null,
        children: [
          {
            path: '/ledger',
            show: isPermissionExist([815]),
            title: 'Accounts Ledger',
            icon: <Icon component={LedgerBook} style={{ fontSize: '20px' }} />,
            component: <></>
          },
          {
            path: '/accountsPayable',
            title: 'Payable',
            show: isPermissionExist([821]),
            icon: <Icon component={PayableIcon} style={{ fontSize: '20px' }} />,
            component: <></>
          },
          {
            path: '/accountReceivables',
            title: 'Receivables',
            show: isPermissionExist([827]),
            icon: <Icon component={ReceivableIcon} style={{ fontSize: '20px' }} />,
            component: <></>
          },

        ]
      },
    ]
  },
  {
    path: '/userManagement',
    title: 'User Management',
    show: isPermissionExist([1]),
    icon: <Icon component={UserManagementIcon} style={{ fontSize: '20px' }} />,
    children: [
      // {
      //     path: '/attribute',
      //     key: '/attribute',
      //     title: 'Attributes',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <AttributePage />,
      // },
      // {
      //     path: '/applications',
      //     key: '/applications',
      //     title: 'Applications',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <ApplicationPage />,
      // },
      // {
      //     path: '/modules',
      //     key: '/modules',
      //     title: 'Modules',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <ModulePage />,
      // },
      // {
      //     path: '/menu',
      //     key: '/menu',
      //     title: 'Menu',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <MenuPage />,
      // },
      // {
      //     path: '/sub-menu',
      //     key: '/sub-menu',
      //     title: 'Sub-Menu',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <SubMenuPage />,
      // },
      // {
      //     path: '/scopes',
      //     key: '/scopes',
      //     title: 'Scopes',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <ScopePage />,
      // },
      // {
      //     path: '/permissions',
      //     key: '/permissions',
      //     title: 'Permissions',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <PermissionsPage />,
      // },
      // {
      //     path: '/client',
      //     key: '/client',
      //     title: 'Client',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <OrganizationPage />,
      // },
      // {
      //     path: '/clientToApplicationMapping',
      //     key: '/clientToApplicationMapping',
      //     title: 'Client-Application',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <ClientToApplicationsMapping />,
      // },
      {
        path: '/roles',
        key: '/roles',
        title: 'Roles',
        show: isPermissionExist([1]),
        icon: <antdIcons.HomeOutlined />,
        component: <RolesPage />,
      },
      {
        path: '/user-creation',
        key: '/user-creation',
        title: 'User',
        show: isPermissionExist([1]),
        icon: <antdIcons.HomeOutlined />,
        component: <UserPage />,
      },
      {
        path: '/user-role-mappings',
        key: '/user-role-mappings',
        title: 'User Role Mapping',
        show: isPermissionExist([1]),
        icon: <antdIcons.HomeOutlined />,
        component: <UserRoleMappings />,
      },
      // {
      //   path: '/role-permissions-mapping',
      //   key: '/role-permissions-mapping',
      //   title: 'Role Permissions Mapping',
      //   show: isPermissionExist([1]),
      //   icon: <antdIcons.HomeOutlined />,
      //   component: <RolePermissionsMapping />,
      // },
      // {
      //     path: '/client-menu',
      //     key: '/client-menu',
      //     title: 'Client Menu',
      //     icon: <antdIcons.HomeOutlined />,
      //     component: <> </>,
      //     children: [
      //         {
      //             path: '/units',
      //             key: '/units',
      //             title: 'Units',
      //             icon: <antdIcons.HomeOutlined />,
      //             component: <UnitPage />,
      //         },
      //         {
      //             path: '/roles',
      //             key: '/roles',
      //             title: 'Roles',
      //             icon: <antdIcons.HomeOutlined />,
      //             component: <RolesPage />,
      //         },
      //         {
      //             path: '/user-creation',
      //             key: '/user-creation',
      //             title: 'User',
      //             icon: <antdIcons.HomeOutlined />,
      //             component: <UserPage />,
      //         },
      //         {
      //             path: '/user-role-mappings',
      //             key: '/user-role-mappings',
      //             title: 'User Role Mapping',
      //             icon: <antdIcons.HomeOutlined />,
      //             component: <UserRoleMappings />,
      //         },
      //         {
      //             path: '/role-permissions-mapping',
      //             key: '/role-permissions-mapping',
      //             title: 'Role Permissions Mapping',
      //             icon: <antdIcons.HomeOutlined />,
      //             component: <RolePermissionsMapping />,
      //         },
      //     ]
      // }

    ]
  },
  {
    path: '/costingReports',
    title: 'Reports',
    show: isPermissionExist([1]),
    icon: <Icon component={PieChartTwoTone} style={{ fontSize: '20px' }} />,
    children: [
      {
        path: '/costing-reports',
        key: '/reports',
        title: 'Costing Reports',
        show: isPermissionExist([1]),
        // icon: <PieChartTwoTone />,
        component: <CostingReports />,
      },
    ]
  }

];


const BasicLayout = React.memo(() => {
  const [collapsed, setCollapsed] = useState(false);

  const fistTime = localStorage.getItem('firstTimeLogin')
  useEffect(() => {
    if (fistTime === 'true') {
      localStorage.setItem('firstTimeLogin', 'false')
      window.location.reload()
    }
  }, [fistTime])

  const toggle = () => {
    setCollapsed(prevCollapsed => !prevCollapsed);
  };

  const getSubMenu = (route) => {
    if (route?.show) {

      if (route && route.children && route.children.length) {
        return {
          key: `${route.key ? route.key : route.path}`,
          icon: route.icon,//React.createElement(route.icon),renderIcon(route.iconType, route.iconName)
          label: route.title,
          children: route.children.map(item => getSubMenu(item))
        }
      } else {
        return {
          key: `${route.key ? route.key : route.path}`,
          icon: route.icon,//React.createElement(route.icon),
          label: (<Link to={`${route.path}`}><span>{route.title}</span></Link>),
        }
      }
    }

  }
  const getAllSubMenus = () => {
    const subMenus: MenuProps['items'] = [];
    menus.forEach(eachRoutes => {
      if (eachRoutes.show)
        subMenus.push(getSubMenu(eachRoutes));
    });
    return subMenus;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        className="layout"
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        collapsedWidth="80"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          background: "#fff",
          marginTop: "60px",
        }}
      >
        <div style={{ height: "calc(100% - 60px)", overflow: "auto" }}>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["1"]}
            style={{ marginTop: "20px" }}
            items={getAllSubMenus()}
          />
        </div>
      </Sider>

      <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 230 }}>
        <CommonHeader key={Date.now()} toggle={toggle} collapsed={collapsed} />
        <Content
          className="site-layout-background"
          style={{
            margin: '78px 16px',
            background: '#f0f2f5',
            height: '100vh'
          }}
        >
          <Outlet />
        </Content>
        {/* <Affix offsetBottom={0}>
                    <Footer style={{ textAlign: 'center', background: '#f0f2f5' }}>©2023 Design and Developed by SchemaX</Footer>
                </Affix> */}
      </Layout>
    </Layout>
  );
})

export default BasicLayout;