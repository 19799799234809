import { Card, Tabs } from "antd";
import ProcurementServiceApproval from "./procurement.service.approval";
import PurchaseContractApproval from "./purchase-contract.approval";
import AdvanceContractApproval from "./advance-contract.approval";

import SalesApproval from "./sales-approval";
import VesselAgreementApproval from "./vessel-agreement.approval";
import BargeAgreementApproval from "./barge-agreement.approval";


const { TabPane } = Tabs;
const ContractApproval = () => {
  return (
    <Card title="Contract Approvals"
    >
    <Tabs>
      <TabPane tab="Sales Contract" key="1">
        <SalesApproval approval={"Sales Approval"}/>
      </TabPane>
      <TabPane tab="Purchase Contract" key="2">
        <PurchaseContractApproval/>
      </TabPane>
      <TabPane tab="Procurement Service" key="3">
        <ProcurementServiceApproval/>
      </TabPane>
      <TabPane tab="Advance Contracts" key="4">
        <AdvanceContractApproval/>
      </TabPane>
      <TabPane tab="Vessel Agreements" key="5">
        <VesselAgreementApproval/>
      </TabPane>
      {/* <TabPane tab="Barge Agreements" key="6">
        <BargeAgreementApproval/>
      </TabPane> */}
      
     </Tabs>
     </Card>

  )
}

export default  ContractApproval;