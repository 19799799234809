import { BNoRequest, BusinessNumberService, BusinessResponseNumberDto } from "@exportx/shared-models-and-services";
import { Affix, Button, Card, Col, Descriptions, Layout, Row, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages } from "../../../../common";
import queryString from 'query-string';

export const BusinessNumberDetailView = () => {

    const { formatMessage: fm } = useIntl();
    const printRef = React.useRef();
    const { state }: any = useLocation();
    const navigate = useNavigate();
    //let { businessNo } = state ? state : { businessNo: '' };
    const location = useLocation();
    const paramBargeId = queryString.parse(location.search)?.bn_Id;
    console.log(paramBargeId,"=====================")
    console.log(queryString.parse(location.search));
    let { businessNo } = paramBargeId != null ? { businessNo: paramBargeId } : state ? state : { businessNo: null };

    const [previewData, setPreviewData] = useState<BusinessResponseNumberDto>(null);
    const [businessNumberCreation, setBusinessNumberCreation] = useState(null);
    const service = new BusinessNumberService(); 

    useEffect(() => {
        getBNoPreviewData(businessNo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessNo]);

    const getBNoPreviewData = (businessNo: string) => {
        const req = new BNoRequest(businessNo);
        service.getBNoPreviewData(req).then(res => {
            if (res.status) {
                setPreviewData(res.data);
                setBusinessNumberCreation(res.data.businessNumberCreation);
                // setShippingPlaning(res.data.shippingPlaning);
                // setvessel(res.data.vessel)
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
    };



    const setFormData = () => {
        navigate('/businessNumber-update', { state: previewData });
    };

    const buyerDemurrageColumns: ColumnsType<any> = [
        {
            title: 'Buyer',
            dataIndex: 'bpName',
            // align: 'center',
            width: 500,
        },

        {
            title: 'Buyer Demurrage',
            dataIndex: 'buyerDemurrage',
            align: 'center',
            render: (value, record) => {
              const formattedValue = value !== null ? value : '';
              const currency = record.buyerDemurrageCurrency !== null ? record.buyerDemurrageCurrency : '';
          
              return (
                <>
                  {formattedValue + ' ' + currency}
                </>
              );
            },
          },
          
          {
            title: 'Buyer Barge Window',
            dataIndex: 'buyerBargeWindow',
            align: 'center',
            render: (value: any, record: any) => {
              if (value && Array.isArray(value) && value.length === 2) {
                const startDate = moment(value[0]);
                const endDate = moment(value[1]);
          
                if (startDate.isValid() && endDate.isValid()) {
                  return (
                    <>
                      {startDate.format('DD-MMM-YYYY')} TO {endDate.format('DD-MMM-YYYY')}
                    </>
                  );
                }
              }
          
              return <></>; // Display 'null' when value is null or invalid
            },
          }
          
    ]

    // const supplierDemurrageColumns: ColumnsType<any> = [
    //     {
    //         title: 'Supplier',
    //         dataIndex: 'bpName',
    //         // align: 'center',
    //     },
    //     {
    //         title: 'Supplier Demurrage',
    //         dataIndex: 'supplierDemurrage',
    //         align: 'center',
    //         // render: (value, record) => {
    //         //   const formattedValue = value !== null ? value : '';
    //         //   const currency = record.supplierCurrency !== null ? record.supplierCurrency : '';
          
    //         //   return (
    //         //     <>
    //         //       {formattedValue + ' ' + currency}
    //         //     </>
    //         //   );
    //         // },
    //       },
    //       {
    //         title: 'Incoterm',
    //         dataIndex: 'IncotermType',
    //         // align: 'center',
    //     },
    //       {
    //         title: 'Supplier Barge Window',
    //         dataIndex: 'supplierBargeWindow',
    //         align: 'center',
    //         // render: (value: any, record: any) => {
    //         //   if (value && Array.isArray(value) && value.length === 2) {
    //         //     const startDate = moment(value[0]);
    //         //     const endDate = moment(value[1]);
          
    //         //     if (startDate.isValid() && endDate.isValid()) {
    //         //       return (
    //         //         <>
    //         //           {startDate.format('DD-MMM-YYYY')} TO {endDate.format('DD-MMM-YYYY')}
    //         //         </>
    //         //       );
    //         //     }
    //         //   }
          
    //         //   return <></>; // Display 'null' when value is null or invalid
    //         // },
    //       },
    //       {
    //         title: 'barge Window',
    //         dataIndex: 'bargeWindowData',
    //         align: 'center',
    //         // render: (value: any, record: any) => {
    //         //   for(const data of value){
    //         //     if (data.bargeWindow && data.bargeWindow.length === 2) {
    //         //     const startDate = moment(data.bargeWindow[0]);
    //         //     const endDate = moment(data.bargeWindow[1]);
          
    //         //     if (startDate.isValid() && endDate.isValid()) {
    //         //       return (
    //         //         <>
    //         //           {startDate.format('DD-MMM-YYYY')} TO {endDate.format('DD-MMM-YYYY')}
    //         //         </>
    //         //       );
    //         //     }
    //         //   }
    //         //   }
    //         //   return <></>; 
    //         // },
       
    //       },
    //       {
    //         title: 'Supplier Quantity',
    //         dataIndex: 'supplierQuantity',
    //       },
          

    // ]


    const anchorageColumns: ColumnsType<any> = [
        {
            title: 'Anchorage',
            dataIndex: 'anchorageName',
            // align: 'center',
            width:400,

        },
        {
            title: 'Estimated Date',
            dataIndex: 'estimateDate',
            align: 'center',
            render: (value) => {
              const formattedDate = moment(value);
              if (formattedDate.isValid()) {
                return <>{formattedDate.format('DD-MMM-YYYY')}</>;
              } else {
                return <></>; // Display "N/A" when the date is not valid
              }
            },
          },
          
        {
            title: 'Laycan',
            dataIndex: 'laycan',
            align: 'center',
            render: (value) => {
                return <>
                    {moment(value[0]).format('DD-MMM-YYYY') + " " + "To" + " " + moment(value[1]).format('DD-MMM-YYYY')}
                </>
            }
        },
    ]


    return (
        <>
            <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>
                <Row justify="end">
                    <Col><>
                        {/* <Button href="/businessnumber" style={{ color: 'black' }}>Business Number Grid</Button> */}
                        <Button style={{ marginLeft: 20 }} onClick={handleDownloadPdf}>Download Business Number</Button>
                    </></Col></Row>
                <Card
                    title={<span >Business Number Detail View - {businessNumberCreation?.['businessNo']} <Tag color="#55acee">
                        {previewData?.['status']}
                    </Tag></span>}
                    style={{ textAlign: 'center' }}
                    headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}
                    ref={printRef}
                // extra={<>{getExtraComponent(previewData?.['status'])}</>}
                >
                    <br></br>
                    <Card title={'Business Number Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label={`Business Type`}>
                                {businessNumberCreation?.businessType}
                            </Descriptions.Item>
                            <Descriptions.Item label="Consignment Type">
                                {businessNumberCreation?.consignmentType}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mother Vessel Name">
                                {businessNumberCreation?.motherVesselName}
                            </Descriptions.Item>

                            <Descriptions.Item label="Estimated Cargo">
                                {businessNumberCreation?.estimatedCargo}
                            </Descriptions.Item>
                            <Descriptions.Item label="Shipment Type">
                                {businessNumberCreation?.shipmentType}
                            </Descriptions.Item>
                            <Descriptions.Item label="IMO No">
                                {businessNumberCreation?.imoNo}
                            </Descriptions.Item>
                            {/* <Descriptions.Item label="Barge Window">
                                {businessNumberCreation?.bargeWindow ? moment(businessNumberCreation?.bargeWindow[0]).format('DD-MMM-YYYY') + " " + "To" + " " + moment(businessNumberCreation?.bargeWindow[1]).format('DD-MMM-YYYY') : ''}
                            </Descriptions.Item> */}

                        </Descriptions>
                    </Card>
                    <br></br>
                    <Card title={'Buyer Demurrage'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        {/* <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="Vessel Type">
                                {shippingPlaning?.vesselType}
                            </Descriptions.Item>
                            <Descriptions.Item label="Stevedore Vendor">
                                {shippingPlaning?.stevedoreVendor}
                            </Descriptions.Item>
                            <Descriptions.Item label="Floating Crane Vendor">
                                {shippingPlaning?.floatingCraneVendor}
                            </Descriptions.Item>

                            <Descriptions.Item label="Loading Port Agent">
                                {shippingPlaning?.loadingPortAgent ? moment(shippingPlaning?.loadingPortAgent).format('YYYY-MMM-DD') : " "}
                            </Descriptions.Item>
                            <Descriptions.Item label="ETA / NOR">
                                {shippingPlaning?.etanor ? moment(shippingPlaning?.etanor).format('YYYY-MMM-DD') : " "}
                            </Descriptions.Item>
                            <Descriptions.Item label="Loading Rate per Day">
                                {shippingPlaning?.loadRate ? moment(shippingPlaning?.loadRate).format('YYYY-MMM-DD') : " "}
                            </Descriptions.Item>

                        </Descriptions> */}
                        <Table pagination={false} size="middle" columns={buyerDemurrageColumns} dataSource={businessNumberCreation?.buyerDemurrage}></Table>
                    </Card>
                    <br></br>
                    <Card title={'Supplier Demurrage'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        {/* <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="NOR Acceptance">
                                {vessel?.norAcceptance}
                            </Descriptions.Item>
                            <Descriptions.Item label="Vessel Arrived On">
                                {vessel?.vesselArrived}
                            </Descriptions.Item>
                            <Descriptions.Item label="Vessel Sailing">
                                {vessel?.vesselSailing}
                            </Descriptions.Item>

                            <Descriptions.Item label="Supply Tonnage In Mt">
                                {vessel?.vesselFinalQuantity}
                            </Descriptions.Item>
                            <Descriptions.Item label="Document On Board">
                                {vessel?.documentOnLoad}
                            </Descriptions.Item>

                        </Descriptions> */}
                        <table className='table table-bordered table-sm'>
                    <thead className='table-header' style={{ background: '#fafafa' }}>
                        {['Supplier','Supplier Demurrage','Incoterm Type','Supplier Barge Window','Barge Window', 'Barge Qty','Supplier Quantity']?.map((item) => (
                            <th className='text-center'>{item}</th>
                        ))}</thead>
                    <tbody>
                        {businessNumberCreation?.supplierDemurrage?.map((e, index) => e.bargeWindowData.map((item, idx) => {
                            return (
                                <tr key={item.id}>
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.bargeWindowData.length}>{e.bpName}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.bargeWindowData.length}>{e.supplierDemurrage}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.bargeWindowData.length}>{e.IncotermType}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}}rowSpan={e.bargeWindowData.length}>{e.supplierBargeWindow}</td>
                                    }
                                        <td className='text-uppercase text-center'  style={{verticalAlign:'middle'}}>{`${moment(item.bargeWindow[0]).format('DD-MM-YYYY')} to ${moment(item.bargeWindow[1]).format('DD-MM-YYYY')}`}</td>
                                    
                                        <td className='text-uppercase text-center'  style={{verticalAlign:'middle'}}>{`${item.sdQuantity || ''}`}</td>
                                    
                                    
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center'  style={{verticalAlign:'middle'}} rowSpan={e.bargeWindowData.length}>{e.supplierQuantity}</td>
                                    }
                                </tr>
                            )
                        }
                        ))}
                    </tbody>
                </table>
                        {/* <Table columns={supplierDemurrageColumns} dataSource={businessNumberCreation?.supplierDemurrage} pagination={false}></Table> */}
                    </Card>
                    <br />
                    <Card title={'Loading Anchorage'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        <Table columns={anchorageColumns} dataSource={businessNumberCreation?.loadingAnchorage} pagination={false}></Table>
                    </Card>

                </Card><br></br>
                <Affix offsetBottom={0}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify='space-between'>
                            <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                            <Col><Button onClick={setFormData}>Edit</Button></Col>
                        </Row>
                    </Card>
                </Affix>
            </Layout>
        </>
    )
}
export default BusinessNumberDetailView;
