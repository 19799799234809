import { AddendumDto, ContractDetailsDataDto, ContractModesEnum, ContractTypesEnum, PurchaseContractDto, PurchaseShippingTermsDto } from '@exportx/shared-models-and-services';
import { Card, Col, Descriptions, Row, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
interface IContractDetailsViewProps {
    contractDetails: ContractDetailsDataDto;
    shippingTerms: PurchaseShippingTermsDto;
    contractMode: ContractModesEnum;
    addendumData: AddendumDto[];
    plantCode: string;
    snapShotData: PurchaseContractDto[];
    contractType: ContractTypesEnum;
}
export const ContractDetailsView = (props: IContractDetailsViewProps) => {
    const { contractDetails, addendumData, shippingTerms, contractMode, contractType, plantCode, snapShotData } = props;
    const link = `/#/detailCustomer?bp_id=${contractDetails?.businessPartner}`;
    let pathToreDirectToSale = '/#/so-detail-view';
    const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
        for (const snapData of snapShotData) {
            let valueAtGivenKey: any = snapData;
            for (const key of keys) {
                valueAtGivenKey = valueAtGivenKey?.[key];
            }
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    if (isRawData) {
                        return value;
                    } else {
                        return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData.contractDetails.contractNo.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    }
                }
            }
        };
        return value
    }
    
    
    return (
        <div>
            <Card title={'Contract Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                <Row>
                    <Col span={(addendumData && addendumData.length != 0) ? 20 : 24}>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label={contractMode === ContractModesEnum.SALES ? "Customer" : "Vendor"}>
                                <a className="link-primary" href={link}>{valiDateWithSnapShotData(['contractDetails', 'businessPartnerName'], contractDetails?.businessPartnerName, false)}</a>
                            </Descriptions.Item>
                            <Descriptions.Item label="Contract Date">
                                {moment(valiDateWithSnapShotData(['contractDetails', 'contractDate'], contractDetails?.contractDate, true)).format('DD MMM YYYY')}
                            </Descriptions.Item>
                            <Descriptions.Item label="Currency">
                                {valiDateWithSnapShotData(['contractDetails', 'currency'], contractDetails?.currency, false)}
                            </Descriptions.Item>

                            <Descriptions.Item label="Total Quantity">
                                {valiDateWithSnapShotData(['contractDetails', 'totalQty'], contractDetails?.totalQty, false)}
                            </Descriptions.Item>

                            <Descriptions.Item label="Facilitator">
                                {valiDateWithSnapShotData(['contractDetails', 'facilitatorName'], contractDetails?.facilitatorName, false)}

                            </Descriptions.Item>
                            <Descriptions.Item label="Commodity">
                                {valiDateWithSnapShotData(['contractDetails', 'commodityName'], contractDetails?.commodityName, false)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Vessel Type">
                                {valiDateWithSnapShotData(['shippingTerms', 'vesselType'], shippingTerms?.vesselType, false)}
                            </Descriptions.Item>

                            <Descriptions.Item label="Loading Rate For Geared">
                                {valiDateWithSnapShotData(['shippingTerms', 'loadingRateForGeared'], shippingTerms?.loadingRateForGeared, false)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Loading Rate For Gearless">
                                {valiDateWithSnapShotData(['shippingTerms', 'loadingRateForGearless'], shippingTerms?.loadingRateForGearless, false)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Discharge Rate ">
                                {valiDateWithSnapShotData(['shippingTerms', 'dischargeRate'], shippingTerms?.dischargeRate, false)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved By">
                                {contractDetails?.approvedBy}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved Date">
                                {contractDetails?.approvedOn ? moment(contractDetails?.approvedOn).format('DD-MM-YYYY h:mm A') : ''}
                            </Descriptions.Item>
                        </Descriptions></Col>
                    {(addendumData && addendumData.length != 0) && <Col span={4}>
                        <Card title="Addendum Data">
                            {React.Children.toArray(addendumData?.map((addendum, index) => {
                                const link = contractMode === ContractModesEnum.SALES ? `${pathToreDirectToSale}?sc_id=${addendum.addendumContractId}&plantCode=${plantCode}` : `/#/po-detail-view?pc_id=${addendum.addendumContractId}&plantCode=${plantCode}`;
                                return <><Tooltip placement="top" title={addendum.status}>
                                    <a className="link-primary" href={link} >
                                        {addendum.addendumContractNo}
                                    </a>{addendumData.length !== index + 1 && ','}</Tooltip><br /></>
                            }))}
                        </Card>
                    </Col>}
                </Row>
            </Card>
        </div >
    )
}

export default ContractDetailsView;