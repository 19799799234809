import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { AnchorageService, BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum, BusinessTypesEnum, ConsignmentType, CurrencyService, ShippingTermsDropDownDto, ShippingTermsService } from '@exportx/shared-models-and-services';
import { Card, Col, Flex, Form, FormInstance, Input, Row, Select, Tooltip } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { FormLayout } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
import { ClassValidator } from '@exportx/ui-utils';
import { MinusCircleOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IBusinessNumberProps {
  formRef: FormInstance<any>
  initialValues: any
  layoutType: FormLayout;
  children?: React.ReactNode;

}

const classValidator = new ClassValidator();
export const BusinessNumber = (props: IBusinessNumberProps) => {
  const { formRef, initialValues, layoutType } = props

  const { formatMessage: fm } = useIntl();
  const [consignment, setConsignment] = useState<string>();
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [anchorages, setAnchorages] = useState([]);
  const [shippingTerms, setShippingTerms] = useState<ShippingTermsDropDownDto[]>([]);
  const [supplier, setSupplier] = useState<BPDropDownDto[]>();
  const [buyer, setBuyer] = useState<BPDropDownDto[]>();
  const [initialValuesLocal, setInitialValuesLocal] = useState<any>();
  const [incotermTypes, setIncotermTypes] = useState({});



  const currencyService = new CurrencyService();
  const anchorageService = new AnchorageService();
  const shippingTermsService = new ShippingTermsService();
  const businessPartnerService = new BusinessPartnerService();

  useEffect(() => {
    getCurrencyData();
    getAllAnchorages();
    getAllShippingTerms();
    getAllBusinessPartnersVendorsDropDown();
    getAllBusinessPartnersCustomersDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);


  const getAllBusinessPartnersVendorsDropDown = () => {
    const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)
    businessPartnerService.getAllBusinessPartnersForDropDown(req).then(res => {
      if (res.status) {
        setSupplier(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })

  }



  const getAllBusinessPartnersCustomersDropDown = () => {
    const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.CUSTOMER)
    businessPartnerService.getAllBusinessPartnersForDropDown(req).then(res => {
      if (res.status) {
        setBuyer(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  };


  const consignmentOnChange = (value) => {
    setConsignment(value)
  }

  const shipmentOnChange = (value, index) => {
    setIncotermTypes(prevState => ({
      ...prevState,
      [index]: value,
    }));
  };

  useEffect(() => {
    consignmentOnChange(initialValues.consignmentType);
    // shipmentOnChange(initialValues.shipmentType)
    const initialValuesToBeSet = JSON.parse(JSON.stringify(initialValues));
    console.log(initialValues)
    if (initialValues) {
      // if(initialValues?.bargeWindow) {
      //   let bWindow = initialValues.bargeWindow;
      //   initialValues.bargeWindow = [moment(bWindow?.[0]), moment(bWindow?.[1])];
      // }
      const loadingAnchorage = [...initialValues.loadingAnchorage];

      loadingAnchorage.forEach((rec, index) => {
        if (initialValues.loadingAnchorage[index]?.laycan) {
          initialValues.loadingAnchorage[index] = { ...rec, laycan: [moment(rec?.laycan?.[0]), moment(rec?.laycan?.[1])], estimateDate: rec?.estimateDate ? moment(rec?.estimateDate) : "" }
        }

      });

      // const supplierDemurrage = [...initialValues?.supplierDemurrage];
      // supplierDemurrage.forEach((rec, index) => {
      //   if (initialValues.supplierDemurrage[index]?.supplierBargeWindow) {
      //     initialValues.supplierDemurrage[index] = { ...rec, supplierBargeWindow: [moment(rec?.supplierBargeWindow?.[0]), moment(rec?.supplierBargeWindow?.[1])] };
      //   }
      // });
      const supplierDemurrage = [...initialValues?.supplierDemurrage];

      supplierDemurrage.forEach((rec, index) => {
        if (rec?.supplierBargeWindow && Array.isArray(rec.supplierBargeWindow)) {
          const start = moment(rec.supplierBargeWindow[0]);
          const end = moment(rec.supplierBargeWindow[1]);

          if (start.isValid() && end.isValid()) {
            initialValues.supplierDemurrage[index] = { ...rec, supplierBargeWindow: [start, end] };
          } else {
            console.error(`Invalid date range at index ${index}`);
            // Handle the case of invalid dates here (e.g., set to null or another default value)
          }
        }
        rec?.bargeWindowData?.forEach((re, ind)=>{
          if (re?.bargeWindow && Array.isArray(re.bargeWindow)) {
            const start = moment(re.bargeWindow[0]);
            const end = moment(re.bargeWindow[1]);
  
            if (start.isValid() && end.isValid()) {
              initialValues.supplierDemurrage[index].bargeWindowData[ind] = { ...re, bargeWindow: [start, end] };
            } else {
              console.error(`Invalid date range at index ${index}`);
            }
          }
        })
      });


      const buyerDemurrage = [...initialValues?.buyerDemurrage];

      buyerDemurrage.forEach((rec, index) => {
        if (rec?.buyerBargeWindow && Array.isArray(rec.buyerBargeWindow)) {
          const start = moment(rec.buyerBargeWindow[0]);
          const end = moment(rec.buyerBargeWindow[1]);

          if (start.isValid() && end.isValid()) {
            initialValues.buyerDemurrage[index] = { ...rec, buyerBargeWindow: [start, end] };
          } else {
            console.error(`Invalid date range at index ${index}`);
            // Handle the case of invalid dates here (e.g., set to null or another default value)
          }
        }
      });

    };
    
    setInitialValuesLocal(initialValuesToBeSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);




  const suffixSelector = (path: number, name: string) => (
    <Form.Item name={[path, name]} noStyle label="Please Select">
      <Select
        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
        allowClear showSearch style={{ width: 70 }}>
        {currencies.map(item => {
          return <Option value={item.currencyCode}>{item.currencyName}</Option>
        })}
      </Select>
    </Form.Item>
  );

  const getCurrencyData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrencies(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }
  const getAllAnchorages = () => {
    anchorageService.getAllAnchorages().then(res => {
      if (res.status) {
        setAnchorages(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllShippingTerms = () => {
    shippingTermsService.getAllShippingTermsDropDown().then(res => {
      if (res.status) {
        setShippingTerms(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }


  const setValues = (name: NamePath, value: string, key: string, valueKey: string, values: any[]) => {
    console.log(name, "=====")
    if (value) {
      const labelToBeSet = values.find(el => el[key] === value);
      formRef.setFieldValue(name, labelToBeSet[valueKey]);
    }

  }
  return (

    <Card title="Business Number Details" >
      <Form
        form={formRef}
        initialValues={initialValues}
        layout={layoutType} autoComplete="off"    >
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="Business Type"
              name="businessType"
              rules={
                [
                  { required: true, message: fm({ id: 'mdm.components.businessNumberCreationForm.businessType', defaultMessage: " Please Select Business Type" }) },
                ]}
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear showSearch placeholder={fm({ id: 'mdm.components.businessNumberCreationForm.businessType', defaultMessage: " Please Select Business Type" })}>
                <Option value={''}>Please Select</Option>
                {Object.keys(BusinessTypesEnum).map(key => {
                  return <Option value={BusinessTypesEnum[key]}>{BusinessTypesEnum[key]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label={fm({ id: 'mdm.components.businessNumberCreationForm.consignmentType', defaultMessage: "Consignment Type" })}
              name="consignmentType"
              rules={
                [
                  { required: true, message: fm({ id: 'mdm.components.businessNumberCreationForm.consignmentType', defaultMessage: " Please Enter ConsignmentType" }) },
                ]}
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear showSearch placeholder="Consignment Type" onChange={consignmentOnChange}>
                <Option value={''}>Please Select</Option>
                {Object.keys(ConsignmentType).map(key => {
                  return <Option value={ConsignmentType[key]}>{ConsignmentType[key]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} style={{ display: consignment === ConsignmentType.MOTHER_VESSEL ? 'unset' : 'none' }}>
            <Form.Item
              label={fm({ id: 'mdm.components.businessNumberCreationForm.motherVesselName', defaultMessage: " Please Enter Mother Vessel Name" })}
              name="motherVesselName"
              rules={
                [
                  { required: consignment === ConsignmentType.MOTHER_VESSEL ? true : false, message: fm({ id: 'mdm.components.businessNumberCreationForm.motherVesselName', defaultMessage: " Please Enter Mother Vessel Name " }) },
                ]}
            >
              <Input placeholder={fm({ id: 'mdm.components.businessNumberCreationForm.motherVesselName', defaultMessage: " Please Enter Mother Vessel Name " })} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} style={{ display: consignment === ConsignmentType.BARGE ? 'unset' : 'none' }}>
            <Form.Item
              label={fm({ id: 'mdm.components.businessNumberCreationForm.barge', defaultMessage: "Barge" })}
              name="bargeName"
              rules={
                [
                  { required: consignment === ConsignmentType.BARGE ? true : false, message: fm({ id: 'mdm.components.businessNumberCreationForm.bargeName', defaultMessage: "Barge Name " }) },
                ]}
            >
              <Input placeholder={fm({ id: 'mdm.components.businessNumberCreationForm.bargeName', defaultMessage: " Barge Name " })} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item
              label={'IMO Number'}
              name="imoNo"
              rules={
                [
                  { required: consignment === ConsignmentType.MOTHER_VESSEL ? false : false, message: fm({ id: 'mdm.components.businessNumberCreationForm.bargeName', defaultMessage: "Barge Name " }) },
                ]}
            >
              <Input placeholder='Please Enter IMO Number' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item
              label={fm({ id: 'mdm.components.businessNumberCreationForm.ShipmentType', defaultMessage: "Shipment Type" })}
              name="shipmentType"
              rules={
                [
                  { required: true, message: fm({ id: 'mdm.components.businessNumberCreationForm.shipmentType', defaultMessage: "Shipment Type " }) },
                ]}
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear showSearch placeholder={fm({ id: 'common.select', defaultMessage: "Shipment Type" })} >
                 <Option value={''}>Please Select</Option>
                {shippingTerms.map(item => {
                  return <Option value={item.shipmenttermName}>{item.shipmenttermName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item
              label={fm({ id: 'mdm.components.businessNumberCreationForm.estimatedCargo', defaultMessage: "Estimated Cargo " })}
              name="estimatedCargo">
              <Input placeholder={fm({ id: 'mdm.components.businessNumberCreationForm.estimatedCargo', defaultMessage: " Please Enter Estimated Cargo " })} />
            </Form.Item>
          </Col>
        </Row>

        <Form.List
          name="loadingAnchorage"
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...resetField }, index) => (
                <>
                  <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 2 }} >
                      <Form.Item name={[name, "anchorageName"]}
                        style={{ display: 'none' }}
                      >
                        <Input hidden />
                      </Form.Item>
                      <Form.Item
                        label={fm({ id: 'mdm.components.businessNumberCreationFrom.anchorage', defaultMessage: "Anchorage" })}
                        name={[name, "anchorage"]}
                        rules={[{ required: true, message: "Required Anchorage" }]}
                      >
                        <Select
                          onChange={(value) => setValues(['loadingAnchorage', name, "anchorageName"], value, 'anchorageId', 'anchorageName', anchorages)}
                          allowClear
                          showSearch
                          placeholder={fm({ id: 'common.select', defaultMessage: "Anchorage" })}
                          //onChange= {handleSelectChange}
                          filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                        >

                          {
                            anchorages.map(rec => {
                              return <Option value={rec.anchorageId} >{rec.anchorageName}</Option>
                            })
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 3 }} >
                      <Form.Item rules={[{
                        required: true,
                        message: "Required Laycan"
                      }]} label={'Laycan'} name={[name, 'laycan']} >
                        <RangePicker />

                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 3 }} >
                      <Form.Item
                        initialValue={undefined}
                        label={fm({ id: 'mdm.components.businessNumberCreationForm.estimateDate', defaultMessage: "Estimated ETA" })}
                        name={[name, "estimateDate"]}>
                        <DatePicker style={{ width: "230px" }} />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2, offset: 18 }} style={{ paddingTop: 17 }}>

                      {fields.length > 1 ? (
                        <DeleteFilled
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                          style={{ marginLeft: "264px", paddingTop: "5px", color: "red", fontSize: 20 }}
                        />
                      ) : null}
                    </Col>

                  </Row>
                  <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                    </Col>
                    {(fields.length - 1 === index) && <span >
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 14 }}>

                        <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                          <Form.Item style={{ paddingLeft: 200 }}>
                            <PlusCircleOutlined
                              type="dashed"
                              onClick={() => add()}
                              style={{ fontSize: 20 }}
                            >
                              Add field
                            </PlusCircleOutlined>
                          </Form.Item>
                        </Tooltip>
                      </Col> </span>}
                  </Row>
                </>
              ))}

            </>
          )}
        </Form.List>

        <Form.List
          name="supplierDemurrage"
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, ...resetField }, index) => {
                console.log('name', name, 'index', index, 'new', formRef.getFieldValue(['supplierDemurrage'])[index]?.IncotermType);
                return (
              
                  <>
                    <Row>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6, offset: 2 }}
                      >
                        <Form.Item
                          name={[name, 'bpName']}
                          style={{ display: 'none' }}
                        >
                          <Input hidden />
                        </Form.Item>
                        <Form.Item
                          label={fm({
                            id: 'mdm.components.businessNumberCreationForm.supplier',
                            defaultMessage: 'Supplier',
                          })}
                          name={[name, 'customer']}
                        >
                          <Select
                            onChange={(value) =>
                              setValues(
                                ['supplierDemurrage', name, 'bpName'],
                                value,
                                'bpId',
                                'bpName',
                                supplier,
                              )
                            }
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toString()
                                .toLocaleLowerCase()
                                .includes(input.toLocaleLowerCase())
                            }
                            allowClear
                            showSearch
                            placeholder={'Select Supplier'}
                          >
                            {supplier?.map((rec) => {
                              return (
                                <Option value={rec.bpId}>{rec.bpName}</Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6, offset: 2 }}
                      >
                        <Form.Item
                          label={fm({
                            id: 'mdm.components.businessNumberCreationForm.IncotermType',
                            defaultMessage: 'Incoterm',
                          })}
                          name={[name, 'IncotermType']}
                          rules={[
                            {
                              required: false,
                              message: fm({
                                id: 'mdm.components.businessNumberCreationForm.IncotermType',
                                defaultMessage: 'Incoterm Type ',
                              }),
                            },
                          ]}
                        >
                          <Select
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toString()
                                .toLocaleLowerCase()
                                .includes(input.toLocaleLowerCase())
                            }
                            allowClear
                            showSearch
                            placeholder={fm({
                              id: 'common.select',
                              defaultMessage: 'Incoterm Type',
                            })}
                            onChange={(value) => shipmentOnChange(value, index)}
                          >
                            <Option value={''}>Please Select</Option>
                            {shippingTerms.map((item) => {
                              return (
                                <Option value={item.shipmenttermName}>
                                  {item.shipmenttermName}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6, offset: 1 }}
                      >
                        <Form.Item
                          label={fm({
                            id: 'mdm.components.businessNumberCreationForm.supplierDemurrage',
                            defaultMessage: 'Supplier Demurrage',
                          })}
                          name={[name, 'supplierDemurrage']}
                          rules={[...classValidator.onlyNumeric()]}
                        >
                          <Input
                            addonAfter={suffixSelector(
                              name,
                              'supplierCurrency',
                            )}
                            placeholder={fm({
                              id: 'mdm.components.businessNumberCreationForm.supplierDemurrageReq',
                              defaultMessage: 'Supplier Demurrage',
                            })}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item
                          name={[name, 'supplierBargeWindow']}
                          label={fm({
                            id: 'mdm.components.bargeDetails.supplierBargeWindow',
                            defaultMessage: 'Supplier (Laycan)',
                          })}
                        >
                          <RangePicker style={{ width: 200 }} />
                        </Form.Item>

                        {fields.length > 1 ? (
                          <DeleteFilled
                            className="dynamic-delete-button"
                            onClick={() => remove(name)}
                            style={{
                              marginLeft: '264px',
                              paddingTop: '5px',
                              color: 'red',
                              fontSize: 20,
                            }}
                          />
                        ) : null}
                      </Col>
                      {formRef.getFieldValue(['supplierDemurrage', name, 'IncotermType']) !== 'FAS ANCHORAGE' && (
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6, offset: 2 }}
                      >
                        <Form.Item
                          label={fm({
                            id: 'mdm.components.businessNumberCreationForm.quantity',
                            defaultMessage: 'Quantity',
                          })}
                          name={[name, 'supplierQuantity']}
                        >
                          <Input placeholder="Quantity" />
                        </Form.Item>
                      </Col>
                      )}
                    </Row>

                    {formRef.getFieldValue(['supplierDemurrage', name, 'IncotermType']) === 'FAS ANCHORAGE' && (
                          <Form.List name={[name, "bargeWindowData"]}>
                            {(bwFields, { add: bwAdd, remove: bwRemove }) => (
                              <>
                                {bwFields.map(({ key: bwKey, name: bwName, ...bwRestField },index) => (
                                  <Row key={bwKey}>
                                    <Col offset={2}>
                                      <Form.Item
                                        label="Barge Window"
                                        {...bwRestField}
                                        name={[bwName, 'bargeWindow']}

                                      >
                                        <RangePicker />
                                      </Form.Item>
                                      </Col>
                                    <Col span={5} offset={2}>
                                      <Form.Item
                                        label={fm({
                                          id: 'mdm.components.businessNumberCreationForm.quantity',
                                          defaultMessage: 'Barge Quantity',
                                        })}
                                        {...bwRestField}
                                        name={[bwName, 'sdQuantity']}

                                      >
                                        <Input placeholder="Quantity" />
                                      </Form.Item>
                                    </Col>
                                    <Col
                                      xs={{ span: 2 }}
                                      md={{ span: 2 }}
                                      lg={{ span: 1, offset: 1 }}
                                    >
                                      <Tooltip title="Remove">
                                        <MinusCircleOutlined
                                          onClick={() => bwRemove(index)}
                                          style={{
                                            color: 'red',
                                            fontSize: '20px',
                                            marginTop: '8px',
                                          }}
                                        />
                                      </Tooltip>
                                    </Col>
                                  </Row>
                                ))}

                                <Row>
                                  <Col offset={17}>
                                    <Tooltip title="Add Barge Window">
                                      <PlusCircleOutlined
                                        onClick={() => bwAdd()}
                                        style={{
                                          color: '#1890ff',
                                          fontSize: '22px',
                                        }}
                                      />
                                    </Tooltip>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Form.List>
                      // </Row>
                    )}

                    <Row>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6, offset: 2 }}
                      ></Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6, offset: 2 }}
                      ></Col>
                      {fields.length - 1 === index && (
                        <span>
                          <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 6, offset: 14 }}
                          >
                            <Tooltip
                              placement="top"
                              title={fm({
                                id: 'common.add',
                                defaultMessage: 'Add',
                              })}
                            >
                              <Form.Item style={{ paddingLeft: 200 }}>
                                <PlusCircleOutlined
                                  type="dashed"
                                  onClick={() => add()}
                                  style={{ fontSize: 20 }}
                                >
                                  Add field
                                </PlusCircleOutlined>
                              </Form.Item>
                            </Tooltip>
                          </Col>{' '}
                        </span>
                      )}
                    </Row>
                  </>
                );}
              )}

            </>
          )}
        </Form.List>

        <Form.List
          name="buyerDemurrage"
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...resetField }, index) => (
                <>
                  <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                      <Form.Item name={[name, "bpName"]}
                        style={{ display: 'none' }}
                      >
                        <Input hidden />
                      </Form.Item>
                      <Form.Item
                        label={'Buyer'}
                        name={[name, "buyer"]}
                      >
                        <Select
                          onChange={(value) => setValues(['buyerDemurrage', name, "bpName"], value, 'bpId', 'bpName', buyer)}
                          filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                          allowClear showSearch key='buyer' placeholder={'Select Buyer'}
                        >
                          <Option value={''}>Please Select</Option>
                          {buyer?.map((rec) => {
                            return <Option value={rec.bpId}>{rec.bpName}</Option>
                          })}
                        </Select>

                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                      <Form.Item label={fm({ id: 'mdm.components.businessNumberCreationForm.buyerDemurrage', defaultMessage: "Buyer Demurrage" })}
                        name={[name, "buyerDemurrage"]}
                        rules={[
                          ...classValidator.onlyNumeric()
                        ]}><Input addonAfter={suffixSelector(name, 'buyerDemurrageCurrency')} placeholder={fm({ id: 'mdm.components.businessNumberCreationForm.buyerDemurrageReq', defaultMessage: "Buyer Demurrage" })} /></Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                      <Form.Item
                        name={[name, "buyerBargeWindow"]}
                        label={fm({ id: "mdm.components.bargeDetails.buyerBargeWindow", defaultMessage: 'Buyer (Laycan)' })}>
                        <RangePicker style={{ width: 200 }} autoComplete='off' />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <DeleteFilled
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                          style={{ marginLeft: "264px", paddingTop: "5px", color: "red", fontSize: 20 }}
                        />
                      ) : null}
                    </Col>

                  </Row>
                  <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                    </Col>
                    {(fields.length - 1 === index) && <span >
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 10 }}>

                        <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                          <Form.Item style={{ paddingLeft: 227 }}>
                            <PlusCircleOutlined
                              type="dashed"
                              onClick={() => add()}
                              style={{ fontSize: 20 }}
                            >
                              Add field
                            </PlusCircleOutlined>
                          </Form.Item>
                        </Tooltip>
                      </Col> </span>}
                  </Row>
                </>
              ))}

            </>
          )}
        </Form.List>
      </Form>
      {props.children}
    </Card >
  )
}

export default BusinessNumber;
