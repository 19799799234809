import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { getLocalFormat } from "@exportx/ui-utils";
import { Table, Typography } from "antd";
import moment from "moment";
import { useAuthState } from "../../../common";
import { BargeCostingIdReqDto, BargeCostingsService, BusinessPartnerTypeEnum, ContractStatusEnum, ContractStatusReqDto, GetDataDeadFreightDto, GetExpApprovalDataDto } from "@exportx/shared-models-and-services"
import { ApprovalTracking } from "./approval-tracking";


const { Text } = Typography;

export const DeadFreightApprovalDetails = ({ costingId, remainingAmount }) => {
  const navigate = useNavigate();
  const { authContext } = useAuthState();
  const bargeCostingService = new BargeCostingsService();

  const [data, setData] = useState<any>();
  useEffect(() => {

    const req = new BargeCostingIdReqDto(costingId, authContext.defaultPlant)
    bargeCostingService.getDeadFreightDataById(req).then(res => {
      if (res.status) {
        setData(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })

  }, [costingId])

  return (
    <>
      <table className="table table-bordered table-sm mt-4">
        <tr>
          <th colSpan={2}>dead Freight</th>
          <td> <Text>{getLocalFormat(data?.deadFreight, data?.baseCurrency)}</Text></td>
        </tr>
        <tr>
          <th colSpan={2}>Price</th>
          <td> <Text>{getLocalFormat(data?.pricePerMT, data?.quoteCurrency)}</Text></td>
        </tr>
        <tr>
          <th colSpan={2}>Exchange Date</th>
          <td>{data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</td>
        </tr>
        <tr>
          <th colSpan={2}>Exchange Rate</th>
          <td>
            <Text>{data?.exchangeRate}</Text>
          </td>
        </tr>
        <tr>
          <th colSpan={2}>Amount</th>
          <td>
            <Text>{getLocalFormat(data?.amount, data?.baseCurrency)}</Text>

          </td>
        </tr>
        <tr>
          <th colSpan={2}>Discount</th>
          <td> <Text>{getLocalFormat(data?.discount, data?.baseCurrency)}</Text></td>
        </tr>
        <tr>
          <th colSpan={2}>Amount After Discount</th>
          <td>
            <Text>{getLocalFormat(data?.amtAftDiscount, data?.baseCurrency)}</Text>

          </td>
        </tr>
        <tr>
          <th>TAX 1</th>
          <td>
            <Text>{`${data?.taxName1 || ''} - ${data?.taxPercent1}`}</Text>
          </td>
          <td>{getLocalFormat(data?.taxTotal1, data?.baseCurrency)}</td>
        </tr>
        <tr>
          <th>TAX 2</th>
          <td>
            <Text>{`${data?.taxName2 || ''} - ${data?.taxPercent2}`}</Text>
          </td>
          <td>
            <Text>{getLocalFormat(data?.taxTotal2, data?.baseCurrency)}</Text>
          </td>
        </tr>
        <tr>
          <th>TDS:</th>
          <td><Text>{`${data?.tdsName} - ${data?.tdsPercentage}`}</Text></td>
          <td>{getLocalFormat(data?.tdsTotal, data?.baseCurrency)}</td>
        </tr>
        <tr>
          <th colSpan={2} > Total Amount</th>
          <td>{getLocalFormat(data?.totalAmount, data?.baseCurrency)}</td>

        </tr>
        <tr>
          <th colSpan={2} >
            To be paid to Supplier
          </th>
          <td>
            <span style={{ color: 'green', fontWeight: 'bold' }}>{getLocalFormat(data?.totalAmount, data?.baseCurrency)}</span>
          </td>
        </tr>
        {/* {remainingAmount &&
                    <tr>
                        <th colSpan={2} >
                            Remaining Amount
                        </th>
                        <td>
                            <span style={{ color: 'red', fontWeight: 'bold' }}>{getLocalFormat(props?.remainingAmount, data?.baseCurrency)}</span>
                        </td>
                    </tr>} */}
      </table>

      <ApprovalTracking history={data?.workLog} />
    </>
  )
}