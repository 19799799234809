import { ContractStatusEnum, PlantCodeReqDto, VesselAgreementService } from "@exportx/shared-models-and-services";
import { Card, Col, Form, Input, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessages } from "../../../../../common";
import Link from 'antd/lib/typography/Link';
import { useAuthState } from "../../../../../common/iam-client-react/iam-client";

const VesselAgreementActiveApproval = () => {
  const [vesselActive, setVesselActive] = useState<any>()
  const { authContext } = useAuthState()
  const [searchData, setSearchData] = useState("")

  const service = new VesselAgreementService();
  let pathToreDirect = '/procurement-contract-detailedView';
  


  useEffect(() => {
    vesselActiveReport()
  }, [])

  const vesselActiveReport = () => {
    const req = new PlantCodeReqDto(authContext.defaultPlant, ContractStatusEnum.ACTIVE);
    service.getAllBargeAgreement(req).then(res => {
      if (res.status) {
        setVesselActive(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)

    })

  }

  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bpName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.approvedBy)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },


      render: (value, rowData) => {
        const link = `/#/vesselAgreement-detailed-view?va_Id=${rowData.agreementId}`;
        return <> {
          <Link href={link} className="link-primary" >{value}</Link>
        }
        </>
      }
    },
    {
      title: 'Contract Type',
    //   dataIndex: 'contractType',
      dataIndex: 'contractMode'
    },
    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (value, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Vendor',
    //   dataIndex: 'bpName',
      dataIndex: 'vendor',
    },
    {
      title: 'Approved By',
      dataIndex: 'approvedBy',
    },
  ]
  return (
    <Card>


      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>



      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={vesselActive}></Table>

    </Card>

  )

}
export default VesselAgreementActiveApproval;