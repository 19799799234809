import { CPSPreviewDto, ContractStatusEnum, PlantCodeReqDto, ProcurementContractService, VesselAgreementPreviewDto, VesselAgreementService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Form, Input, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../../../common";



const VesselAgreementPendingApproval = () => {
  const [vesselPending, setProcurementPending] = useState<any>()
  const [searchData, setSearchData] = useState("")

  const service = new VesselAgreementService()
  let pathToreDirect = '/procurement-contract-detailedView';
  const { authContext } = useAuthState()

  useEffect(() => {
    vesselAgreementPendingReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const vesselAgreementPendingReport = () => {
    const req = new PlantCodeReqDto(authContext.defaultPlant, ContractStatusEnum.PENDING_APPROVAL);
    service.getAllBargeAgreement(req).then(res => {
      if (res.status) {
        setProcurementPending(res.data)
      } else {
        setProcurementPending([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })

  }



  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bpName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.action)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },


      render: (value, rowData) => {
        // const link = `/#${pathToreDirect}?cpcId=${rowData.contractId}`
        const link = `/#/vesselAgreement-detailed-view?va_Id=${rowData.agreementId}`;
        return <> {
          <a href={link} className="link-primary" >{value}</a>
        }
        </>
      }
    },

    {
      title: 'Contract Type',
    //   dataIndex: 'contractType',
      dataIndex: 'contractMode'
    },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Vendor',
    //   dataIndex: 'bpName',
      dataIndex: 'vendor'
    },


    {
      title: 'Action',
      dataIndex: 'action',
      align: 'Center',
      render: (text, rowData) => {
        return (
            <span>
                <Button size="small" onClick={() => {
                    approveOrRejectHandler(rowData.agreementId, ContractStatusEnum.ACTIVE);
                }}>Approve</Button>
                <Button size="small" onClick={() => {
                    approveOrRejectHandler(rowData.agreementId, ContractStatusEnum.REJECT);
                }}>Reject</Button>
            </span>
        );
      }

    },
  ]


const approveOrRejectHandler = (agreementId: string, status: ContractStatusEnum) => {
    const req = new VesselAgreementPreviewDto(agreementId, authContext.defaultPlant, authContext.user.userName, status)
    service.approveOrRejectAgreement(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        vesselAgreementPendingReport();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <Card>


      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >


          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>



      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={vesselPending} ></Table>

    </Card>

  )

}
export default VesselAgreementPendingApproval;